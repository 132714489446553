
import { Vue, Component } from 'vue-property-decorator'
import { apiStorageList, apiStorageChange } from '@/api/setting'
@Component({
  components: {}
})
export default class StorageIndex extends Vue {
    /** S Data **/
    list = []
    oldEngine = 'local' // 默认引擎
    /** E Data **/

    /** S Methods **/
    // 跳转引擎设置页面
    onStorageEdit (item: any) {
      this.$router.push({
        path: '/setting/storage/storage',
        query: {
          engine: item.engine // 存储引擎
        }
      })
    }

    // 获取存储引擎列表
    getStorageList () {
      apiStorageList()
        .then((res: any) => {
          this.list = res
          console.log(res)
          // this.$message.success("删除成功!");
        })
        .catch(() => {
          this.$message.error('获取数据失败，请重试！')
        })
    }

    // 切换引擎
    postStorageChange (item: any) {
      apiStorageChange({ engine: item.engine })
        .then((res: any) => {
          this.getStorageList()
          this.$message.success('更新成功')
        })
        .catch(() => {
          this.$message.error('更新失败')
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.getStorageList()
    }
    /** E Life Cycle **/
}
